import React from 'react';
import { withTranslation } from "react-i18next";
import API from '../api';
import Judge from '../components/Judge';
import ContentWrap from '../components/ContentWrap';
import Hero from '../components/content/Hero';
import { CenterModal, ModalCloseTarget } from 'react-spring-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import {
  Box,
  Markdown
} from 'grommet'

class Judges extends React.Component {

  state = {
    judges: [],
    popup: false,
    popupOpen: false,
  }

  componentDidMount(){
    this.loadJudges();
  }

  setPopup = (judge) => {
    this.setState({
      popup: judge,
      popupOpen: true,
    })
  }

  closePopup = (judge) => {
    this.setState({
      popupOpen: false,
    })
    setTimeout(() => { this.setState({popup: judge}) }, 500)
  }


  renderPopup = () => {
    if (this.state.popup){
      return (
        <Box className="popupJudge">
          <img alt={this.state.popup.fields.name} src={this.state.popup.fields.image.fields.file.url} />
          <Box className="popupJudgeBody">
          <h1>{this.state.popup.fields.name}</h1>
          <h2>{this.state.popup.fields.jobTitle}</h2>
          <Markdown className="popupJudgeText">{this.state.popup.fields.bio}</Markdown>
          </Box>
        </Box>
      )
    } else {
      return null
    }
  }

  loadJudges = () => {
    /**
    API.getLocales()
    .then( (res) => {
      console.log(res);
    })
    .catch(console.error)
    **/

    // Get Recipes
    API.getJudges(this.props.i18n).then( (res) => {
      console.log(res);
      this.setState({
        judges: res.items
      })
    })
    .catch(console.error)
  }

  render() {
    const { match, t, i18n } = this.props;

    return (
      <Box id="page" className="">

      <CenterModal
        isOpen={this.state.popupOpen}
        onDismiss={() => this.closePopup(false)}
        contentTransition={{
          from: { background: 'lightcoral', transform: 'translateY(-100%)' },
          enter: { background: 'lightcyan', transform: 'translateY(0)' },
          leave: { background: 'lightcoral', transform: 'translateY(-100%)' }
        }}
        //contentProps={{ style: staticModalStyles }}
      >
      <ModalCloseTarget>
        <button className="modalClose"><FontAwesomeIcon icon={faTimesCircle} /></button>
      </ModalCloseTarget>
        {this.renderPopup()}
      </CenterModal>

      <Hero title="Selection Committee" />
      <ContentWrap padding={true}>
        <Box
          wrap={true}
          direction="row"
        >

        { this.state.judges.map( (judge, index) => {
          return (
            <Judge key={index} judge={judge} popup={this.state.popup} setPopup={this.setPopup} />
          )
        })}

        </Box>
      </ContentWrap>
      </Box>
    );
  }
}

export default withTranslation("translations")(Judges);
