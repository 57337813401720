import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { pathToRegexp, compile } from 'path-to-regexp';
import Cookies from 'universal-cookie';
import { connect } from 'react-redux'
import { setLocale } from '../../actions/cartActions'

import {
  Box,
  DropButton
} from 'grommet';
import ReactCountryFlag from "react-country-flag"

const LanguageSelector = ({ i18n, match, location, locale, setLocale }) => {
  const [menuOpen, setMenuOpen] = useState({}); // Open voting

  const cookies = new Cookies();

  const setLanguageCookie = (lng) => {
      cookies.set('language', lng, { path: '/' });
  }

  const generateLanguage = (locale, location) => {
    const ROUTE = "/:locale/:path*";
    const definePath = compile(ROUTE);
    const routeComponents = pathToRegexp(ROUTE).exec(location.pathname);

    let subPaths = null;
    if (routeComponents && routeComponents[2]) {
      subPaths = routeComponents[2].split("/");
    }

    return definePath({
      locale,
      path: subPaths
    });
  };

  const changeLanguage = (lng, dispatch) => {
    i18n.changeLanguage(lng);
    setLanguageCookie(lng);
    if (dispatch){
      dispatch(lng);
    }
    setTimeout(() => {window.location.reload()}, 500) // Reload to fetch new content from Contentful
  };

  const getFlagFromLocale = (locale) => {
    if (!locale){
      return <ReactCountryFlag countryCode="US" />
    }
    switch(locale.toLowerCase()){
      case 'eu':
        return <ReactCountryFlag countryCode="EU" />
      case 'en-gb':
        return <ReactCountryFlag countryCode="GB" />
      case 'en-us':
        return <ReactCountryFlag countryCode="US" />
      case 'de-de':
        return <ReactCountryFlag countryCode="DE" />
      case 'fr-fr':
        return <ReactCountryFlag countryCode="FR" />
      default:
        return <ReactCountryFlag countryCode="US" />
    }
  }

  return (
    <div className="LanguageSelector">
      <DropButton
        className="LanguageSelectorButton"
        //label={locale}
        dropAlign={{ top: 'bottom', right: 'right' }}
        dropProps={{style: {zIndex: 105, width: "150px", borderRadius: "5px", marginBottom: "5px"} }}
        dropContent={
          <Box className="LanguageSelectorPopup" background="light-2">
            <Link to={generateLanguage("en-US", location)}>
              <button onClick={() => changeLanguage("en-US", setLocale)}>
              <ReactCountryFlag countryCode="US" />
                USA
              </button>
            </Link>

            <Link to={generateLanguage("en-GB", location)}>
              <button onClick={() => changeLanguage("en-GB", setLocale)}>
                <ReactCountryFlag countryCode="GB" />
                UK
                </button>
            </Link>

            <Link to={generateLanguage("de-DE", location)}>
              <button onClick={() => changeLanguage("de-DE", setLocale)}>
                <ReactCountryFlag countryCode="DE" />
                Germany
              </button>
            </Link>

            <Link to={generateLanguage("fr-FR", location)}>
              <button onClick={() => changeLanguage("fr-FR", setLocale)}>
                <ReactCountryFlag countryCode="FR" />
                France
              </button>
            </Link>

            <Link to={generateLanguage("eu", location)}>
              <button onClick={() => changeLanguage("eu", setLocale)}>
                <ReactCountryFlag countryCode="EU" />
                EU
              </button>
            </Link>
          </Box>
        }
      >
      {getFlagFromLocale(i18n.language)} {i18n.language}
      </DropButton>
    </div>
  )
}

// Map redux state to props (remember to export below with this function)
const mapStateToProps = (state) => {
    return {
      locale: state.cart.locale
    }
}

// Map redux dispatches to props (remember to export below with this function)
const mapDispatchToProps = (dispatch) => {
  return {
    setLocale: (locale) => { dispatch(setLocale(locale)) }
  }
}

//export default LanguageSelector
export default connect(mapStateToProps, mapDispatchToProps)(LanguageSelector)
