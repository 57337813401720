import React from 'react';
import {
  Box
} from 'grommet'

const ContentWrap = ({children, padding, nopadding, fullwidth = false}) => {
  return (
    <Box
      className="contentWrapOuter"
    >
    <Box
      className={fullwidth ? "" : "contentWrapInner"}
      //width={nopadding ? "auto" : "large"}
      width="large"
      margin={
        {
          top: padding ? "large" : "auto",
          bottom: padding ? "large" : "auto",
          left: "auto",
          right: "auto"
        }
      }
    >
      {children}
    </Box>
  </Box>
  )
}

export default ContentWrap;
