import React from 'react';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import {
  Box,
  Button,
  Image,
  Text
} from 'grommet'
import { Router, Link } from "react-router-dom";
import logo from '../../assets/images/logo.png';

const ComponentCTA = (props) => {
  const { field, match, t, i18n } = props;
  //console.log("componentCTA")
  //console.log(field)

  const renderLogo = () => {
    if (field.fields.hasOwnProperty('showMpbLogo') && field.fields.showMpbLogo){
      return (
        <img
          src={logo}
          alt="MPB"
          style={{
             width: "100px"
          }}
        />
      )
    } else {
      return null;
    }
  }

  const renderTitle = () => {
    if (field.fields.title.length > 60){
      // If title too long then render as a smaller font size
      return (
        <h2 className="smaller">
        {field.fields.title}
        </h2>
      )
    } else {
      return (
        <h2>
        {field.fields.title}
        </h2>
      )
    }
  }

  const renderSubtitle = () => {
    if (field.fields.hasOwnProperty('subtitle') && field.fields.subtitle){
      return (
        <h3>
        {field.fields.subtitle}
        </h3>
      )
    } else {
      return null;
    }
  }

  const renderButton = () => {
    if (
      field.fields.hasOwnProperty('ctaText')
      && field.fields.hasOwnProperty('ctaLink')
      && field.fields.ctaText
      && field.fields.ctaLink
    ){
      if (field.fields.ctaLink.substring(0, 4).toLowerCase() == "http"){
        return (
        // Normal link
          <Button
            secondary
            href={field.fields.ctaLink}
            target="_blank"
            label={field.fields.ctaText}
          />
        )
      } else {
        return (
          <Link to={`${i18n.language}${field.fields.ctaLink}`}>
            <Button
              secondary
              label={field.fields.ctaText}
            />
          </Link>
        )
      }
    } else {
      return null;
    }
  }

  return (
    <Box
      className="cta"
      direction="column"
      pad="medium"
      align="center"
      background={`url(${field.fields.image.fields.file.url})`}
    >
      <div className="overlay"></div>
      <div className="aboveOverlay">
      {renderLogo()}
      {renderTitle()}
      {renderSubtitle()}
      {renderButton()}
      </div>
    </Box>
  )
}

export default ComponentCTA;
