import {
  SET_LOCALE,
  ADD_VOTE,
  REMOVE_VOTE,
  SUBMIT_VOTED,
  CLEAR_VOTES,
  SET_CATEGORIES
} from './action-types/cart-actions'

// Set locale
export const setLocale = (locale) => {
    return {
        type: SET_LOCALE,
        locale
    }
}

// Set locale
export const setCategories = (categories) => {
    return {
        type: SET_CATEGORIES,
        categories
    }
}

// Add vote
export const addVote = (product, category, category_id) => {
    return {
        type: ADD_VOTE,
        product,
        category,
        category_id
    }
}

// Remove vote
export const removeVote = (category_id) => {
    return {
        type: REMOVE_VOTE,
        category_id
    }
}

// Submit votes
export const submitVoted = () => {
    return {
        type: SUBMIT_VOTED
    }
}

// Clear votes
export const clearVotes = () => {
    return {
        type: CLEAR_VOTES
    }
}
