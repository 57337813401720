import React from "react";
import {
  Box
} from 'grommet'

function FadeInJudge(props) {
  const [isVisible, setVisible] = React.useState(true);
  const [isInitiated, setInitiated] = React.useState(0); // Is initialised
  const domRef = React.useRef();

  const triggerVisible = (visible) => {
    //  Use this function to only trigger once
    setVisible(visible)
    if (visible){
      setInitiated(1);
    }
  }

  const observer = new IntersectionObserver(entries => {
    entries.forEach(entry => {
      //setVisible(entry.isIntersecting)
      triggerVisible(entry.isIntersecting);
    });
  },{threshold: 0.005});

  React.useEffect(() => {
    observer.observe(domRef.current);
    //return () => observer.unobserve(domRef.current);
    return () => { observer.disconnect() }
  }, []);

  const getVisibleClassName = () => {
      var delay = props.delay % 3;
      //console.log("judge");
      //console.log(props.delay);
      //console.log(delay);
      if (isInitiated == 1){
        // Always show visible after initiated
        return `judge fade-in-section delay-${delay} is-visible`
      } else {
        return `inductee fade-in-section delay-${delay} ${isVisible ? 'is-visible' : ''}`
      }
  }

  return (
    <Box
      flex="grow"
      basis={props.calcBasis()}
      direction="row"
      justify="start"
      flex={{
        grow: 0,
        shrink: 1,
      }}
      align="end"
      pad="medium"
      margin="1%"
      className={getVisibleClassName()}
      ref={domRef}
      //background={props.background}
      onClick={() => {props.onClick()}}
    >
      {props.children}
    </Box>
  );
}

export default FadeInJudge;
