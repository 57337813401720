import React from 'react';
import {
  Box,
} from 'grommet'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog } from '@fortawesome/free-solid-svg-icons';

// Wrapper around page to provide loading/preloader
const PageWrap = (props) => {

  const checkContentLoaded = () => {
    if (props.content){
      return (
        <div className="transitionSlideDown_DISABLED">
        {props.children}
        </div>
      )
    } else {
      return (
        <Box
          className="hero heroDefault"
        >
          <h1 className="loading"><FontAwesomeIcon icon={faCog} spin /> Loading</h1>
        </Box>
      )
      // Old preloader
      return (
        <Box
          fill={true}
          //flex="1 0 auto"
          direction="column"
          justify="center"
          align="center"
          height="50vh"
        >
          <Box style={{position: "absolute", top: "40%"}}>
            <h1><FontAwesomeIcon icon={faCog} spin /> Loading</h1>
          </Box>
        </Box>
      )
    }
  }

  return (
    <Box
      className="pageWrap"
    >
      {checkContentLoaded()}

    </Box>
  )
}

export default PageWrap;
