const Settings = {}

Settings.RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY;

if (process.env.REACT_APP_SHOW_CATEGORIES === "true"){
  Settings.SHOW_CATEGORIES = true;
} else {
  Settings.SHOW_CATEGORIES = false;
}
//Settings.SHOW_CATEGORIES = true;

if (process.env.REACT_APP_ALLOW_VOTING === "true"){
  //console.log("REACT_APP_ALLOW_VOTING: true")
  Settings.ALLOW_VOTING = false; // force no voting
} else {
  //console.log("REACT_APP_ALLOW_VOTING: false")
  Settings.ALLOW_VOTING = false;
}

if (process.env.REACT_APP_VOTING_CLOSED === "true"){
  console.log("REACT_APP_VOTING_CLOSED: true")
  Settings.VOTING_CLOSED = true;
} else {
  console.log("REACT_APP_VOTING_CLOSED: false")
  Settings.VOTING_CLOSED = true; // Force voting closed
}

//Settings.ALLOW_VOTING = true;
//Settings.VOTING_CLOSED = false;
Settings.ENDPOINT_VOTE = process.env.REACT_APP_ENDPOINT_VOTE;
Settings.ENDPOINT_NEWSLETTER = process.env.REACT_APP_ENDPOINT_NEWSLETTER;
Settings.ENDPOINT_PERIOD = process.env.REACT_APP_ENDPOINT_PERIOD;

Settings.languageFallback = "en-US"

// These languages/Locales are available on the site
Settings.languages = [
  'en-US',
  'en-GB',
  'EU',
  'de-DE',
  'fr-FR',
]

Settings.languagesCatchall = [
  'en-US',
  'en-us',
  'en-GB',
  'en-gb',
  'EU',
  'eu',
  'de-DE',
  'de-de',
  'fr-FR',
  'fr-fr',
]

// Tese languages will be redirected to the EU site
Settings.languagesEU = [
  'AD',
  'bs',
  'bg',
  'bg-BG',
  'bs-BA',
  'ca-AD',
  'ca-ES',
  'ca',
  'hr-HR',
  'hr',
  'cs-CZ',
  'cs',
  'da-DK',
  'da',
  'nl-BE',
  'nl-NL',
  'nl',
  'en-AU',
  'en-IE',
  'en-MT',
  'et-EE',
  'et',
  'fi-FI',
  'fi',
  'el-CY',
  'el-GR',
  'el',
  'hu-HU',
  'hu',
  'ga-IE',
  'ga',
  'gl',
  'gl-ES',
  'it-IT',
  'it-CH',
  'it',
  'lv-LV',
  'lv',
  'lt-LT',
  'lt',
  'mt-MT',
  'mt',
  'pl-PL',
  'pl',
  'pt-PT',
  'ro-RO',
  'ro',
  'sk-SK',
  'sk',
  'sl-SI',
  'sl',
  'es-ES',
  'sv-SE',
  'sv',
  // Technically not EU countries
  'bs',
  'bs-BA',
  'sq',
  'sq-AL',
  'hy',
  'hy-AM',
  'az',
  'az-AZ',
  'be',
  'be-BY',
  'bs',
  'bs-BA',
  'ka',
  'ka-GE',
  'kk',
  'kk-KZ',
  'sr-BA',
  'sr-SP',
  'sr-SP',
  'mk',
  'mk-MK',
  'tr',
  'tr-TR',
  'uk',
  'uk-UA',
]

// Tese languages will be redirected to the FR site
Settings.languagesUK = [
  'en-GB',
]

// Tese languages will be redirected to the FR site
Settings.languagesFR = [
  'fr-BE',
  'fr-CA',
  'fr-LU',
  'fr-CH',
  'fr-FR',
  'fr-MC',
]

// Tese languages will be redirected to the DE site
Settings.languagesDE = [
  'de-AT',
  'de-DE',
  'de-LU',
  'de-CH',
  'de-LI',
  'de',
]

export default Settings;
