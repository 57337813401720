import React from 'react';
import {
  Box,
} from 'grommet'

const ComponentSpacer = ({field}) => {
  //console.log("componentText")
  //console.log(field)
  return (
    <div
      className={"spacer-" + field.fields.size}
    >
    </div>
  )
}

export default ComponentSpacer;
