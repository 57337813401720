import React from 'react';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import {
  Box,
  Image
} from 'grommet'

const ComponentImage = ({field}) => {
  //console.log("componentText")
  //console.log(field)
  return (
    <Box>
      <Image
        fit="cover"
        a11yTitle={field.fields.title}
        alt={field.fields.title}
        src={field.fields.image.fields.file.url}
      />
    </Box>
  )
}

export default ComponentImage;
