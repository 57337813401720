import React, { useState, useEffect } from 'react';
import {
  Box,
  Image,
  Text
} from 'grommet';
import  { Redirect } from 'react-router-dom'
import PageWrap from "../components/PageWrap";
import {Helmet} from "react-helmet";
import hof_logo from '../assets/images/HOF-LOGO-transparent-white.png';
import hof_logo_2x from '../assets/images/HOF-LOGO-transparent-white@2x.png';

const NotFound = (props) => {
    const { match, t, i18n } = props;
    const content = {
      entries : [
        {
          fields : [{
            name: "Not Found"
          }]
        }
      ]
    }

    const redirects = {
      '/sitemap/' : '/',
      '/sitemap' : '/',
      '/privacy-policy' : '/en-US/privacy-policy',
      '/privacy-policy/' : '/en-US/privacy-policy/',
      '/votingcategory/' : '/',
      '/votingcategory' : '/',
      '/votingcategory/classic/' : '/',
      '/votingcategory/classic' : '/',
      '/votingcategory/game-changer/' : '/',
      '/votingcategory/game-changer' : '/',
      '/votingcategory/iconic/' : '/',
      '/votingcategory/iconic' : '/',
      '/votingcategory/road-tested/' : '/',
      '/votingcategory/road-tested' : '/',
      '/votingcategory/trendsetter/' : '/',
      '/votingcategory/trendsetter' : '/',
    }

    // Use specified redirects
    if (redirects.hasOwnProperty(window.location.pathname)){
      console.log("redirecting to: " + redirects[window.location.pathname])
      return <Redirect to={redirects[window.location.pathname]} />
    }

    // Redirect to proper 404 page
    if (window.location.pathname != "/en-US/not-found"){
      return <Redirect to="/en-US/not-found" />
    }

    return (
      <div style={{
        background: "#140d40",
        color: "#fff",
        height: "80%",
        paddingTop: "10%",
        paddingBottom: "20%",
        width: "100%",
        textAlign: "center"
      }}>
        <Helmet>
          <title>Page Not Found</title>
          <meta name="robots" content="noindex" />
          <meta name="robots" content="nofollow" />
        </Helmet>
        <Box id="page">
          <Image
            className="categoryImage"
            fit="contain"
            fill={false}
            src={hof_logo}
            srcSet={`${hof_logo} 1x, ${hof_logo_2x} 2x, `}
            style={{maxWidth: "500px", margin: "0 auto"}}
          />
          <h1 style={{ fontSize: "60px;"}}>Page Not Found</h1>
        </Box>
      </div>
    );
}

export default NotFound
