
const shuffleArray = (array) => {
  var currentIndex = array.length,  randomIndex;
  // While there remain elements to shuffle...
  while (0 !== currentIndex) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;
    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex], array[currentIndex]];
  }
  return array;
}

// Calc basis for rows of items
const calcRowBasis = (size) => {
  //console.log(size)
  if (size === "xsmall"){
    return "100%"
  } else if (size === "small"){
    return "49%"
  } else if (size === "large"){
      return "32%"
  } else {
    return "32%"
  }
}

const scrollToID = (id) => {
  var hashElement = document.getElementById(id)
  if (hashElement){
      // Need to delay this so that the menu slides up first then we can calculate correctly the location to scroll to
      setTimeout(
        () => hashElement.scrollIntoView({ behavior: 'smooth' }),
        800
      )
  }
}

// Parse Query string
const parseQueryString = function() {
    var str = window.location.search;
    var objURL = {};
    str.replace(
        new RegExp( "([^?=&]+)(=([^&]*))?", "g" ),
        function( $0, $1, $2, $3 ){
            objURL[ $1 ] = $3;
        }
    );
    return objURL;
};

export {shuffleArray, calcRowBasis, scrollToID, parseQueryString}
