import React from 'react';
import {
  Box,
} from 'grommet'

import Hero from '../components/content/Hero';
import ComponentText from '../components/content/Text';
import ComponentTextImage from '../components/content/TextImage';
import ComponentImage from '../components/content/Image';
import ComponentVideo from '../components/content/Video';
import ComponentCTA from '../components/content/CTA';
import ComponentNewsletter from '../components/content/Newsletter';
import ComponentInductees from '../components/content/Inductees';
import ComponentJudges from '../components/content/Judges';
import ComponentAccordion from '../components/content/Accordion';
import ComponentSpacer from '../components/content/Spacer';
import ComponentGrid from '../components/content/Grid';
import Categories from '../pages/Categories';

let renderItems = (fields, match, t, i18n, nopadding, fullwidth) => {
  return (
      Object.keys(fields).map( (key, index) => {
        //console.log("== field: " + key + " ==")
        var field = fields[key]
        var contentType = field.sys.contentType.sys.id;
        //console.log(field)

        // Text
        if (contentType === "component_text"){
          return (
            <div key={index}>
              <ComponentText field={field} fullwidth={fullwidth} />
            </div>
          )
        }

        // Text/Image
        if (contentType === "component_textimage"){
          return (
            <div key={index}>
              <ComponentTextImage field={field} fullwidth={fullwidth}/>
            </div>
          )
        }

        // Image
        if (contentType === "component_image"){
          return (
            <div key={index}>
              <ComponentImage field={field} fullwidth={fullwidth} />
            </div>
          )
        }

        // CTA
        if (contentType === "component_cta"){
          return (
            <div key={index}>
              <ComponentCTA field={field} match={match} t={t} i18n={i18n} fullwidth={fullwidth} />
            </div>
          )
        }

        // Newsletter
        if (contentType === "component_newsletter"){
          return (
            <div key={index}>
              <ComponentNewsletter field={field} match={match} t={t} i18n={i18n} fullwidth={fullwidth} />
            </div>
          )
        }

        // Video
        if (contentType === "component_video"){
          return (
            <div key={index}>
              <ComponentVideo field={field} fullwidth={fullwidth} />
            </div>
          )
        }

        // Accordion
        if (contentType === "component_accordion"){
          return (
            <div key={index}>
              <ComponentAccordion field={field} fullwidth={fullwidth} />
            </div>
          )
        }

        // Categories
        if (contentType === "component_categories"){
          return (
            <div key={index}>
              <Categories field={field} match={match} t={t} i18n={i18n} fullwidth={fullwidth} />
            </div>
          )
        }

        // Inductees
        if (contentType === "component_inductees"){
          return (
            <div key={index}>
              <ComponentInductees field={field} match={match} t={t} i18n={i18n} fullwidth={fullwidth} />
            </div>
          )
        }

        // Committee / Judges
        if (contentType === "component_committee"){
          return (
            <div key={index}>
              <ComponentJudges field={field} match={match} t={t} i18n={i18n} fullwidth={fullwidth} />
            </div>
          )
        }

        // Spacer
        if (contentType === "componentSpacer"){
          return (
            <div key={index}>
              <ComponentSpacer field={field} fullwidth={fullwidth} />
            </div>
          )
        }

        // Grid
        if (contentType === "component_grid"){
          return (
            <div key={index}>
              <ComponentGrid field={field} match={match} t={t} i18n={i18n} fullwidth={fullwidth} />
            </div>
          )
        }

        return (
          <div key={index}>
            Field: {key}
          </div>
        )
      })
  )
}

let heroRender = (content, match, t, i18n) => {
  if (
    !content
    || !content.hasOwnProperty('fields')
    || !content.fields.hasOwnProperty('hero')
  ){
    if (!content.hasOwnProperty('fields')){
      return null
    } else {
      if (!content.fields.hasOwnProperty('pageTitle')){
        return null
      } else {
        return (
          <Hero title={content.fields.pageTitle} content={content.fields.hero} />
        )
      }
    }

  } else {
    return (
        <Hero title={false} content={content.fields.hero} />
    )
  }
}

let contentRender = (content, match, t, i18n, nopadding, fullwidth) => {
  if (!content || !content.hasOwnProperty('fields') || !content.fields.hasOwnProperty('content')){
    return <p>No content</p>
  } else {
    return (
      <>
        { renderItems(content.fields.content, match, t, i18n, nopadding, fullwidth) }
      </>
    )
  }
}

// Takes a contentful page (content model)
// and renders out to different components
const Content = ({content, match, t, i18n, nopadding = false, fullwidth = false}) => {
  return (
    <>
      {heroRender(content)}
      <Box className="renderContent transitionSlideDown">

        {contentRender(content, match, t, i18n, nopadding, fullwidth)}
      </Box>
    </>
  )
}

export default Content;
