import React from 'react';
import {
  Box,
  Grid,
  ResponsiveContext,
} from 'grommet';
import ContentWrap from '../ContentWrap';
import Content from '../../utils/RenderContent';

const ComponentGrid = ({field, match, t, i18n}) => {
  //console.log("componentText")
  //console.log(field)
  const size = React.useContext(ResponsiveContext);

  const renderColumns = () => {
      if (field.hasOwnProperty("fields")
      && field.fields
      && field.fields.hasOwnProperty("columns")
      && field.fields.columns){
        return (
          <Grid
            columns={{
              count: (size == "small" || size == "xsmall") ? 1 : field.fields.columns.length,
              size: (size == "small" || size == "xsmall") ? "full" : "auto",
            }}
            gap="medium"
          >
            { field.fields.columns.map( (column, index) => {
              return (
              <Content key={index} content={column} match={match} t={t} i18n={i18n} fullwidth={true} />
            )
          })}
          </Grid>
        )
      } else {
        return null
      }
  }

  return (
    <div
      className="grid"
    >
      <ContentWrap nopadding={false}>
        {renderColumns()}
      </ContentWrap>
    </div>
  )
}

export default ComponentGrid;
