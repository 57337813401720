import React from 'react';
import {Helmet} from "react-helmet";
import Analytics from './Analytics';

const meta_title = process.env.REACT_APP_META_TITLE
const meta_description = process.env.REACT_APP_META_DESCRIPTION
const meta_keywords = process.env.REACT_APP_META_KEYWORDS

const renderImage = (content) => {
  //console.log(content.fields)
  if (
    content.fields.hasOwnProperty("image")
    && content.fields.image
  ){
    console.log("have image")
    return (
      <Helmet>
      <meta property="og:image" content="https://example.com/ogp.jpg" />
      <meta property="og:image:secure_url" content="https://secure.example.com/ogp.jpg" />
      <meta property="og:image:type" content="image/jpeg" />
      <meta property="og:image:width" content="400" />
      <meta property="og:image:height" content="300" />
      </Helmet>
    )
  }
}

let renderSEOTags = (content) => {


  if (content && content.hasOwnProperty('fields') && content.fields.hasOwnProperty('seo')){
    //console.log(content.fields.seo)

    return (
      <Helmet>
      {
        content.fields.seo.fields.hasOwnProperty('title') ? <title>{content.fields.seo.fields.title}</title> : <title>{meta_title}</title>
      }
      {
        content.fields.seo.fields.hasOwnProperty('description') ? <meta name="description" content={content.fields.seo.fields.description} /> : <meta name="description" content={"Have NO seo title"} />
      }
      {
        content.fields.seo.fields.hasOwnProperty("image") && content.fields.seo.fields.image ? <meta property="og:image" content={content.fields.seo.fields.image.fields.file.url} /> : null
      }
      {
        content.fields.seo.fields.hasOwnProperty("image") && content.fields.seo.fields.image ? <meta property="og:image:secure_url" content={content.fields.seo.fields.image.fields.file.url} /> : null
      }
      {
        content.fields.seo.fields.hasOwnProperty("image") && content.fields.seo.fields.image ? <meta property="og:image:type" content={content.fields.seo.fields.image.fields.file.contentType} /> : null
      }
      {
        content.fields.seo.fields.hasOwnProperty("image") && content.fields.seo.fields.image ? <meta property="og:image:width" content={content.fields.seo.fields.image.fields.file.details.image.width} /> : null
      }
      {
        content.fields.seo.fields.hasOwnProperty("image") && content.fields.seo.fields.image ? <meta property="og:image:height" content={content.fields.seo.fields.image.fields.file.details.image.height} /> : null
      }
      </Helmet>

    )
  } else {
    if (content && content.hasOwnProperty('fields') && content.fields.hasOwnProperty('pageTitle')){
      // Use pageTitle title
      return (
        <Helmet>
          <title>{ content.fields.pageTitle }</title>
        </Helmet>
      )
    } else if (content && content.hasOwnProperty('fields') && content.fields.hasOwnProperty('name')){
      // Use page title
      return (
        <Helmet>
          <title>{ content.fields.name }</title>
        </Helmet>
      )
    } else {
      // Use default title
      return (
        <Helmet>
          <title>
            {process.env.REACT_APP_META_TITLE}
          </title>
          <meta name="description" content={meta_description} />
          <meta name="keywords" content={meta_keywords} />
        </Helmet>
      )
    }
  }
}


const sendPageView = (content) => {
  // Track page view
  if (content && content.hasOwnProperty('fields') && content.fields.hasOwnProperty('seo') && content.fields.seo.fields.hasOwnProperty('title')){
    Analytics.TrackPageView(content.fields.seo.fields.title);
  } else {
    if (content && content.hasOwnProperty('fields') && content.fields.hasOwnProperty('pageTitle')){
      Analytics.TrackPageView(content.fields.pageTitle);
    } else if (content && content.hasOwnProperty('fields') && content.fields.hasOwnProperty('name')){
      Analytics.TrackPageView(content.fields.name);
    } else {
      Analytics.TrackPageView(process.env.REACT_APP_META_TITLE);
    }
  }
}

const RenderHelmet = ({content, match, t, i18n}) => {
  sendPageView(content)
  return renderSEOTags(content)
}

export default RenderHelmet
