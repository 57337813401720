import ContentfulClient from '../utils/ContentfulClient';

/**
 * It's possible to sync all content from contentful into
 * and then return delta updates
**/
/**
ContentfulClient.sync({
  initial: true,
  resolveLinks: true
})
.then((response) => {
  console.log("Contentful client sync")
  console.log(response.entries)
  console.log(response.assets)
})
**/

const API = {};

//////////////////
//// Helpers ////
////////////////
API.calcLanguage = function(i18n){
  if (!i18n.hasOwnProperty("language")){
    return 'en-US';
  }
  if (i18n.language.toLowerCase() === 'eu' ){
    return 'en-EU';
    //return 'en-US';
  } else {
    return i18n.language;
  }
}

API.inlineContent = function(content){
  // Move contentful content links and images inline
  content.items.forEach( (item, index) => {
    console.log("item")
    content.items[index].fields = API.inlineFields(item.fields, content.includes)
  })
  return content;
}

///////////////////////
//// VOTING / API ////
/////////////////////
API.submitNewsletter = function (data) {
  var newsletterURL = process.env.REACT_APP_ENDPOINT_NEWSLETTER
  return fetch(newsletterURL, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
    },
    body: JSON.stringify(data)
  })
}

///////////////////////
//// VOTING / API ////
/////////////////////
API.submitVotes = function (data) {
  var voteURL = process.env.REACT_APP_ENDPOINT_VOTE
  return fetch(voteURL, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
    },
    body: JSON.stringify(data)
  })
}

///////////////////////
//// VOTING / dates ////
/////////////////////
API.getVotingPeriod = function () {
  var voteURL = process.env.REACT_APP_ENDPOINT_PERIOD
  return fetch(voteURL, {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
    },
  })
}

//////////////////
//// Content ////
////////////////

API.getLocales = function(){
  //alert("getLocales: api");
  return ContentfulClient.getLocales()
}

// Pages
API.getPages = function (i18n) {
  return ContentfulClient.getEntries({
    content_type: 'page',
    include: 1,
    locale: API.calcLanguage(i18n),
  })
}

// Fetch single page using getEntries (to include assets and includes)
API.getPageNew = function (i18n, id) {
  return ContentfulClient.getEntries({
    'sys.id' : id,
    content_type: 'page',
    include: 10,
    locale: API.calcLanguage(i18n),
  })
}

API.getPage = function (i18n, id) {
  return ContentfulClient.getEntry(id, {
    include: 10, // Max depth
    locale: API.calcLanguage(i18n),
  })
}

API.getPageBySlug = function (i18n, slug) {
  console.log(slug)
  return ContentfulClient.getEntries({
    content_type: 'composePage',
    //select: 'fields',
    'fields.slug': slug,
    include: 10, // Max depth
    locale: API.calcLanguage(i18n),
  })
}

// Categories
API.getCategories = function (i18n) {
  return ContentfulClient.getEntries({
    content_type: 'category',
    include: 10,
    locale: API.calcLanguage(i18n),
  })
}

// Judges
API.getJudges = function (i18n) {
  return ContentfulClient.getEntries({
    content_type: 'judge',
    include: 10,
    locale: API.calcLanguage(i18n),
  })
}

// Testing
API.getRecipes = function(i18n){
  return ContentfulClient.getEntries({
    content_type: 'recipes',
    locale: API.calcLanguage(i18n),
  })
}

export default API;
