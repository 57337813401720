import React, { useState, useEffect } from 'react';
import Countdown from "react-countdown";
import API from '../api';

const CountdownComponent = ({t, categories, countdownDate, countdownText}) => {
  const [startDate, setStartDate] = useState(false);
  const [error, setError] = useState(false);

  // Similar to componentDidMount and componentDidUpdate:
  // Empty array as second param so ocde only runs once
  // https://css-tricks.com/run-useeffect-only-once/
  useEffect(() => {
    //fetchPeriod()
    //alert(countdownDate);
    //setStartDate();
  });

  const convertDate = (d) => {
    return new Date(d);
  }

  const fetchPeriod = () => {
    API.getVotingPeriod().then(response =>
      response.json().then(data => ({
        status: response.status,
        body: data,
      }))
    ).then( (res) => {
      if (res.status === 200){
        // convert to js date
        // #TODO - turn this back on when working
        //var date = new Date(res.body.closes);
        var date = Date.now() + 1000000000
        setStartDate(date);
      } else {
        setError(true);
      }

    })
    .catch(() => {
      setError(true);
    })
  }

  // Random component
  const Completionist = () => <span>Voting is now open please refresh your browser</span>;

  // Renderer callback with condition
  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a complete state
      return <Completionist />;
    } else {
      // Render a countdown
      return (
        <div className="segments">
          <div className="segment">
            <div className="number">
              {days}
            </div>
            <div className="label">
              {t("days")}
            </div>
          </div>
          <div className="segment">
            <div className="number">
              {hours}
            </div>
            <div className="label">
              {t("hours")}
            </div>
          </div>
          <div className="segment">
            <div className="number">
              {minutes}
            </div>
            <div className="label">
              {t("minutes")}
            </div>
          </div>
          <div className="segment segmentSeconds">
            <div className="number">
              {seconds}
            </div>
            <div className="label">
              {t("seconds")}
            </div>
          </div>
        </div>
      );
    }
  };

  const getCountdown = () => {
    if (error){
      return (
        <div className="error">Error fetching start date</div>
      )
    } else if (countdownDate){
      return <Countdown date={convertDate(countdownDate)} renderer={renderer} />
    } else {
      return (
        <div className="loading">Loading...</div>
      )
    }
  }

  return (
    <div className="countdown">
      <h3>{countdownText}</h3>
      <div className="clock">
        {getCountdown()}
      </div>
    </div>
  )
}

export default CountdownComponent;
