import React from 'react';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import {
  Box,
  Text
} from 'grommet'
import RenderRichText from '../../utils/RenderRichText';
import ContentWrap from '../ContentWrap';

const ComponentText = ({field, nopadding = false, fullwidth = false}) => {
  //console.log("componentText")
  //console.log(field)

  function checkBig() {
    if (field.fields.hasOwnProperty('big') && field.fields.big){
      return true
    } else {
      return false
    }
  }

  function checkBigSize() {
    if (field.fields.hasOwnProperty('big') && field.fields.big){
      return "xlarge"
    } else {
      return "medium"
    }
  }

  function checkCentered() {
    if (field.fields.hasOwnProperty('centered') && field.fields.centered){
      return "center"
    } else {
      return "start"
    }
  }

  function checkCenteredPadding() {
    if (field.fields.hasOwnProperty('centered') && field.fields.centered){
      return "large"
    } else {
      return "none"
    }
  }

  return (
    <ContentWrap nopadding={checkBig()} fullwidth={fullwidth}>
      <Box
        //pad={checkCenteredPadding()}
        className={checkCentered()}
      >
        <Text className={checkBig() ? 'bigText' : 'normalText'} size={checkBigSize()} textAlign={checkCentered()} dangerouslySetInnerHTML={{ __html: RenderRichText(field.fields.text) }} />
      </Box>
    </ContentWrap>
  )
}

export default ComponentText;
