import React from 'react';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import {
  Box,
  Image,
  Text
} from 'grommet'
import RenderRichText from '../../utils/RenderRichText';
import ContentWrap from '../ContentWrap';

const ComponentTextImage = ({field}) => {
  console.log("componentTextImage")
  console.log(field)
  if (field.fields.layout == "text_left"){
    return (
      <Box
        direction="row"
        gap="medium"
      >
        <Box basis="1/2">
          <Text dangerouslySetInnerHTML={{ __html: RenderRichText(field.fields.text) }} />
        </Box>
        <Box basis="1/2">
          <Image
            fit="cover"
            a11yTitle={field.fields.title}
            alt={field.fields.title}
            src={field.fields.image.fields.file.url}
          />
        </Box>
      </Box>
    )
  } else {
    return (
      <ContentWrap>
      <Box
        direction="row"
        gap="medium"
      >
        <Box basis="1/2">
          <Image
            fit="cover"
            a11yTitle={field.fields.title}
            alt={field.fields.title}
            src={field.fields.image.fields.file.url}
          />
        </Box>
        <Box basis="1/2">
          <Text dangerouslySetInnerHTML={{ __html: RenderRichText(field.fields.text) }} />
        </Box>
      </Box>
      </ContentWrap>
    )
  }
}

export default ComponentTextImage;
