import React from "react";
import {
  Box
} from 'grommet'

function FadeInInductee(props) {
  const [isVisible, setVisible] = React.useState(true);
  const [isInitiated, setInitiated] = React.useState(0); // Is initialised
  const domRef = React.useRef();

  const checkInit = () => {
    return isInitiated
  }

  const triggerVisible = (visible) => {
    //  Use this function to only trigger once
    setVisible(visible)
    if (visible){
      setInitiated(1);
    }
  }

  const observer = new IntersectionObserver(entries => {
    entries.forEach(entry => {
      //setVisible(entry.isIntersecting)
      triggerVisible(entry.isIntersecting);
    });
  },{threshold: 0.05});

  React.useEffect(() => {
    observer.observe(domRef.current);
    //return () => observer.unobserve(domRef.current);
    return () => { observer.disconnect() }
  }, []);

  const getVisibleClassName = () => {
      if (isInitiated == 1){
        // Always show visible after initiated
        return `inductee fade-in-section delay-${props.delay} is-visible`
      } else {
        return `inductee fade-in-section delay-${props.delay} ${isVisible ? 'is-visible' : ''}`
      }
  }

  return (
    <Box
      //flex="grow"
      basis={props.calcBasis()}
      align="center"
      margin="1%"
      className={getVisibleClassName()}
      ref={domRef}
      background={props.background}
      onClick={() => {props.onClick()}}
      focusIndicator={false}
    >
      {props.children}
    </Box>
  );
}

export default FadeInInductee;
