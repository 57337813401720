import React, { useState, useContext } from 'react';
import FadeInJudge from './utility/FadeInJudge';
import { calcRowBasis } from '../utils/Misc';
import {
  Box,
  Button,
  Markdown,
  ResponsiveContext,
} from 'grommet'


const Judge = ({t, delay, judge, popup, setPopup}) => {
  const size = React.useContext(ResponsiveContext);
  const [isOpen, setOpen] = React.useState(false);


  const calcBasis = () => {
    return calcRowBasis(size)
    /**
    if (size == "small"){
      return "100%"
    } else {
      if (size == "medium"){
        return "31.333333332%"
      } else {
        return "31.333333332%"
      }
    }
    **/
  }

  return (
      <FadeInJudge
        calcBasis={calcBasis}
        //background={`url(${card_bg})`}
        onClick={() => setPopup(judge)}
        key={5}
        delay={delay}
      >
        <Box pad="medium" align="center" width="100%">
          <div className="overlay"></div>
          <div
            className="underlay"
            style={{
              backgroundImage: `url(${judge.fields.image.fields.file.url})`
            }}
          ></div>
          <Box className="judgeTitles">
          <h2>{judge.fields.name}</h2>
          <h3>{judge.fields.jobTitle}</h3>
          </Box>

          <Button secondary label={t("Learn more")} />
        </Box>
      {/**
      </Box>
      **/}
      </FadeInJudge>
  )
}

export default Judge;
