const Theme = {
  global: {
    breakpoints: {
      // Have to remove 16px because of some bug
        xsmall : { value: 576 - 16 },
        small : { value: 768 - 16 },
        medium : { value: 992 - 16 },
        large : { value: 1200 - 16 },
        xlarge : { value: 1400 -16},
    },
    colors: {
      focus: '#F9CF93',
      brand: '#228BE6',
      navLink: '#ffffff',
      navLinkHover: "#F9CF93",
      purple: '#140d40',
      blueDark: '#050507',
      blueMedium: '#140d40',
    },
    focus: {
      shadow: {
        color: "transparent"
      }
    },
    font: {
      family: 'Roboto',
      size: '16px',
      height: '20px',
    },
    drop : {
      zIndex: "30",
      //extend: {backgroundColor: "white"},
    },
    tip : {
      content: {
        "background": "white"
      },
      drop: {
        "background": "white",
      }
    },
    input: {
      font: {
        weight: "300",
        //size: "14px",
        height: "24px",
      },
    },
  },
  text : {
    font: {
      family: 'Roboto',
      size: '32px',
    },
    small: {
      size: '12px',
    },
    medium: {
      size: '14px',
    },
  },
  // Buttons
  button: {
    default: {
      color: '#F9CF93', // Purple
      borderWidth: '0',
      extend: `padding: 10px 20px; text-transform: uppercase; font-family: 'Oswald', sans-serif; font-weight: 500; color: #140d40; border-width: 0; background: linear-gradient(90deg, rgba(208,145,76,1) 0%, rgba(228,177,74,1) 50%, rgba(247,211,76,1) 100%);`,
    },
    primary: {
      color: '#F9CF93', // Gold
      borderWidth: '0',
      extend: `padding: 10px 20px; text-transform: uppercase; font-family: 'Oswald', sans-serif; font-weight: 500; color: #140d40; border-width: 0; background: linear-gradient(90deg, #ce8e47 0%, #f8d44c 100%);`,
    },
    secondary: {
      color: '#ce8e47', // Purple
      borderWidth: '0',
      extend: `padding: 10px 20px; text-transform: uppercase; font-family: 'Oswald', sans-serif; font-weight: 500; color: #fff; border-width: 0; background: #932240; background: linear-gradient(90deg, #140d40 0%, #501933 100%);`,
    },
    font: {
      family: 'Roboto',
      size: '32px',
    },
    hover: {
      secondary: {
        extend: `color: #140d40; background: linear-gradient(90deg, #ce8e47 0%, #f8d44c 100%);`,
      }
    }
  },
  // Forms
  formField : {
    content: {
      margin: {
        bottom: "15px"
      }
    }
  },
  // Accordion
  accordion : {
    hover : {
      heading : {
        color: "#F9CF93"
      }
    }
  },
  // Layer
  layer : {
    background: "transparent",
    overlay: {
      background: "rgba(0, 0, 0, 0.5)"
    }
  },
  // checkbox
  checkBox : {
    color: "#501933"
  },
  // select
  select: {
    options: {
      container: {
        styles: {
          zIndex: "201"
        }
      }
    }
  }
};

export default Theme;
