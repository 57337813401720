import _ from 'lodash';

//export default (state = initialState, action) => {
//  return state;
//}

import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import { loadState } from '../utils/LocalStorage';

// Cart stuff
import {
  SET_LOCALE,
  ADD_VOTE,
  REMOVE_VOTE,
  SUBMIT_VOTED,
  CLEAR_VOTES,
  SET_CATEGORIES
} from '../actions/action-types/cart-actions'

const persistedState = loadState(); // Load from localStorage

const initVotes = { // Will hold their chosen vote for each category
  'classic' : false,
  'game-changer' : false,
  'road-tested' : false,
  'trendsetter' : false,
  'iconic' : false,
}

const initCategories = { // Will hold their chosen vote for each category
  'updated' : false, // Set with date/time when updated
  'classic' : false,
  'game-changer' : false,
  'road-tested' : false,
  'trendsetter' : false,
  'iconic' : false,
}

var initState = {
    locale: false,
    votes: initVotes,
    voted: false, // Set to timestamp they voted
    categories: initCategories
}

// Rehydrate from local storage
if (persistedState && persistedState.hasOwnProperty('cart')){
  if (persistedState.cart.hasOwnProperty('locale')){
    // #TODO - rehydrated product data may have changed
    initState.locale = persistedState.cart.locale
  }
  if (persistedState.cart.hasOwnProperty('categories')){
    // #TODO - rehydrated product data may have changed
    initState.categories = persistedState.cart.categories
  }
  if (persistedState.cart.hasOwnProperty('votes')){
    // #TODO - rehydrated product data may have changed
    var allowed_keys = _.keys(initVotes)
    //console.log(allowed_keys)
    var valid_votes = _.pick(persistedState.cart.votes, allowed_keys)
    //console.log(valid_votes)
    initState.votes = valid_votes
  }
  if (persistedState.cart.hasOwnProperty('voted')){
    // #TODO - rehydrated product data may have changed
    initState.voted = persistedState.cart.voted
  }
  if (persistedState.cart.hasOwnProperty('addedItems')){
    initState.addedItems = persistedState.cart.addedItems
  }
}

const cartReducer = (state = initState, action)=>{

  // Set the users locale in state
  if(action.type === SET_LOCALE){
    //alert("set locale in state");
    var newState_a = {
      ...state,
      locale: action.locale
    }
    return newState_a
  }

  // Set the categories in state
  if(action.type === SET_CATEGORIES){
    var newState_f = {
      ...state,
      categories: action.categories
    }
    return newState_f
  }

  // Add a vote to the vote cart
  if(action.type === ADD_VOTE){
    let votes = {...state.votes} // Make sure is a copy i.e. don't update state here but pass back a copy of state objects
    action.product.category = {
      id : action.category.id,
      name: action.category.name,
      image: action.category.image,
      imageSmall: action.category.imageSmall,
      shortDescription: action.category.shortDescription,
      sorting: action.category.sorting
    }
    votes[action.category_id] = action.product
    var newState_b = {
      ...state,
      votes: votes
    }
    return newState_b
  }

  // Remove a vote from the vote cart
  if(action.type === REMOVE_VOTE){
    console.log("category_id: " + action.category_id)
    let votes = {...state.votes} // Make sure is a copy i.e. don't update state here but pass back a copy of state objects
    votes[action.category_id] = false
    var newState_c = {
      ...state,
      votes: votes
    }
    return newState_c
  }

  // Add a vote to the vote cart
  if(action.type === SUBMIT_VOTED){
    var now = new Date()
    var newState_g = {
      ...state,
      voted: now.toJSON()
    }
    return newState_g
  }

  // Clear votes from state
  if(action.type === CLEAR_VOTES){
    var newState_d = {
      ...state,
      votes: initVotes,
      voted: false,
    }
    // test delete categories
    //delete newState_d.categories
    //console.log(newState_d)
    //return newState_d
    return {
      locale: false,
      votes: initVotes,
      voted: false, // Set to timestamp they voted
      categories: initCategories
    }
  }

  return state
}

// Build the reducers
export const createRootReducer = (history) => combineReducers({
  router: connectRouter(history),
  // extra reducers here?
  cart: cartReducer,
})
//export default createRootReducer
