import { documentToHtmlString } from '@contentful/rich-text-html-renderer';

let options = {
  renderNode: {
    'embedded-asset-block': (node) =>
      `<img class="img-fluid" src="${node.data.target.fields.file.url}"/>`
  }
}

function RenderRichText(content){
  return documentToHtmlString(content, options)
}

export default RenderRichText
