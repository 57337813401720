import React, { useState } from 'react';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import {
  Box,
  Button,
  CheckBox,
  Form,
  FormField,
  Image,
  Select,
  Text,
  TextInput,
} from 'grommet'
import { Router, Link } from "react-router-dom";
import API from '../../api';
import RenderRichText from '../../utils/RenderRichText';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog } from '@fortawesome/free-solid-svg-icons';

const ComponentNewsletter = ({field, match, t, i18n }) => {
  const [submitting, setSubmitting] = useState(false);
  const [success, setSuccess] = useState(false);
  const [submissionError, setSubmissionError]= useState(false); // show something went wrong error
  const [formData, setFormData] = useState({
    email: '',
    emailError: false,
    country: false,
    agreeTerms: false,
    errors: [],
  });

  //const countryOptions = ['United States', 'United Kingdom', 'Canada', 'South America', 'Germany', 'France', 'Other']
  const countryOptions = [
    {"lab" : t('United States'), "value" : 'United States'},
    {"lab" : t('United Kingdom'), "value" : 'United Kingdom'},
    //{"lab" : t('Canada'), "value" : 'Canada'},
    //{"lab" : t('South America'), "value" : 'South America'},
    {"lab" : t('Germany'), "value" : 'Germany'},
    {"lab" : t('France'), "value" : 'France'},
    {"lab" : t('Other'), "value" : 'Other'}
  ]


  const renderTitle = () => {
    if (field.fields.hasOwnProperty('title') && field.fields.title){
      return (
        <h2>
        {field.fields.title}
        </h2>
      )
    } else {
      return null;
    }
  }

  const renderText = () => {
    if (field.fields.hasOwnProperty('text') && field.fields.text){
      return (
        <Box className="subtitle">
          <Text dangerouslySetInnerHTML={{ __html: RenderRichText(field.fields.text) }} />
        </Box>
      )
    } else {
      return null;
    }
  }

  const renderFooterText = () => {
    if (field.fields.hasOwnProperty('footerText') && field.fields.footerText){
      return (
        <p className="tinyText" style={{textAlign: "center"}}>
        {field.fields.footerText}
        </p>
      )
    } else {
      return null;
    }
  }

  const renderSuccessText = () => {
    if (field.fields.hasOwnProperty('successText') && field.fields.successText){
      return (
        <p>
        {field.fields.successText}
        </p>
      )
    } else {
      return null;
    }
  }

  // Used by terms only
  const updateFormValue = (key, value) => {
    var t = {}
    t[key] = value
    // Have to do it like this as react won't re-render components if the same object is just updated
    setFormData({
      ...formData,
      ...t,
    })
  }

  const updateFormEmail = (value) => {
    setFormData({
      ...formData,
      email: value
    })
  }

  const updateFormCountry = (value) => {
    setFormData({
      ...formData,
      country: value
    })
  }

  const validateForm = () => {
    var errors = []
    if (!formData.email){
      errors.push(t("Please enter your email"))
    }
    if (!formData.country){
      errors.push(t("Please select your country"))
    }
    if (!formData.agreeTerms){
      errors.push(t("Please agree to our privacy policy"))
    }

    setFormData({
      ...formData,
      errors: errors
    })

    if (errors.length > 0){
      return false
    } else {
      return true
    }
  }

  const submitForm = (value) => {
    var valid = validateForm();
    if (valid){
      setSubmitting(true)
      var data = {
        email: formData.email
      } // The data we are going to submit

      // Map country to active campaign requirements
      switch(formData.country){
        case 'United States':
          //data.country = "US"
          data.country = "United States"
          break;
        case 'United Kingdom':
          //data.country = "UK"
          data.country = "United Kingdom"
          break;
        case 'Canada':
          //data.country = "US"
          data.country = "Canada"
          break;
        case 'Germany':
          //data.country = "DE"
          data.country = "Germany"
          break;
        case 'France':
          //data.country = "FR"
          data.country = "France"
          break;
        case 'South America':
          //data.country = "US"
          data.country = "South America"
          break;
        default:
          //data.country = "EN-UK"
          data.country = "Other"
      }

      console.log(data)

      // Submit to API
      API.submitNewsletter(data).then( (res) => {
        console.log(res);
        setSubmitting(false)
        if (res.status === 201){
          setSuccess(true);
        } else {
          setSubmissionError(true)
        }
      })
      .catch((e) => {
        console.log("error submitting")
        console.error(e)
        setSubmitting(false)
        setSubmissionError(true)
      })

    }
  }

  const renderErrors = () => {
    return (
      formData.errors.map( (error, index) => {
        return (
          <Text color="red">{error}</Text>
        )
      })
    )
  }

  const renderForm = () => {
    if (success){
      return <Box>{renderSuccessText()}</Box>;
    } else if (submissionError){
      return (
        <Box className="submissionError">
          <p>{t("We do apologise but something seems to have gone wrong")}</p>
          <Button primary label={t("Try again")} onClick={() => setSubmissionError(false)} />
        </Box>
      )
    } else if (submitting){
      return (
        <p><FontAwesomeIcon icon={faCog} spin />  {t("Submitting")}</p>
      )
    } else {
      return (
        <Form
          //value={value}
          //onChange={nextValue => setValue(nextValue)}
          //onReset={() => setValue({})}
          onSubmit={() => {submitForm()}}
        >
          <FormField name="email" htmlFor="text-input-id">
            <TextInput
              value={ formData.email }
              //onChange={({ value }) => updateName(value)}
              onChange={event => updateFormEmail(event.target.value)}
              placeholder={t("your email")}
              id="text-input-id"
              name="email"
              type="email"
            />
          </FormField>
          <FormField name="country" htmlFor="text-input-id">
            <Select
              placeholder={formData.country ? t(formData.country) : t("select your country")}
              options={countryOptions}
              labelKey="lab"
              //children={(option, index, options, { active, disabled, selected }) => {
              //  if (option.value === formData.country){
              //    alert(option.value);
              //  }
              //}}
              value={ formData.country.value }
              //value={currentValue ? currentValue.displayValue : undefined}
              onChange={({ option }) => updateFormCountry(option.value)}
            />
          </FormField>
          <CheckBox
            checked={formData.agreeTerms}
            pad={{bottom:'10px'}}
            //label="By clicking submit you are agreeing to our terms and conditions"
            label={
              <Box>
                <p>{t("I have read and accepted the")} <Link style={{color: "#140d40", display: "inline"}} to={`/${i18n.language}/privacy-policy`} target="_blank">{t("Privacy Policy")}</Link> {t("gelesen und akzeptiere diese")}</p>
              </Box>
            }
            onChange={(event) => updateFormValue("agreeTerms", event.target.checked)}
          />
          <Box direction="row" gap="medium">
            <Button fill="horizontal" type="submit" secondary label={t("sign up")} />
          </Box>
          {renderFooterText()}
        </Form>
      )
    }
  }

  return (
    <Box
      className="newsletter"
      direction="column"
      pad="medium"
      align="center"
      id="newsletter"
    >
      {renderTitle()}
      {renderText()}
      {renderErrors()}
      {renderForm()}
    </Box>
  )
}

export default ComponentNewsletter;
