import React from 'react';
import { withTranslation } from "react-i18next";
import API from '../../api';
import Judge from '../Judge';
import { CenterModal, ModalCloseTarget } from 'react-spring-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { shuffleArray } from '../../utils/Misc';
import Analytics from '../../utils/Analytics';
import _ from 'lodash';
import {
  Box,
  Markdown
} from 'grommet'

class ComponentJudges extends React.Component {

  state = {
    judges: [],
    popup: false,
    popupOpen: false,
  }

  componentDidMount(){
    this.loadJudges();
  }

  setPopup = (judge) => {
    Analytics.TrackPageView(judge.fields.name, "/CommitteeMember/" + _.kebabCase(judge.fields.name));
    this.setState({
      popup: judge,
      popupOpen: true,
    })
  }

  closePopup = (judge) => {
    this.setState({
      popupOpen: false,
    })
    setTimeout(() => { this.setState({popup: judge}) }, 500)
  }


  renderPopup = () => {
    if (this.state.popup){
      return (
        <Box className="popupJudge">
          <img alt={this.state.popup.fields.name} src={this.state.popup.fields.image.fields.file.url} />
          <Box className="popupJudgeBody">
          <h1>{this.state.popup.fields.name}</h1>
          <h2>{this.state.popup.fields.jobTitle}</h2>
          <Markdown
            className="popupJudgeText"
            components={
              {
                "p": {
                  "component": "p",
                  "props": {"size": "small"}
                }
              }
            }
          >
            {this.state.popup.fields.bio}
          </Markdown>
          </Box>
        </Box>
      )
    } else {
      return null
    }
  }

  loadJudges = () => {
    API.getJudges(this.props.i18n).then( (res) => {
      var sortedJudges = _.sortBy(res.items, o => o.fields.name.split(" ")[1])// Use Lodash to sort array by 'name'
      this.setState({
        //judges: shuffleArray(res.items)
        judges: sortedJudges
      })
    })
    .catch(console.error)
  }

  render() {
    const { field, match, t, i18n } = this.props;

    return (
      <Box className="judgesWrapper contentNarrowWidth">
      <CenterModal
        isOpen={this.state.popupOpen}
        onDismiss={() => this.closePopup(false)}
        contentTransition={{
          from: { background: 'lightcoral', transform: 'translateY(-100%)' },
          enter: { background: 'lightcyan', transform: 'translateY(0)' },
          leave: { background: 'lightcoral', transform: 'translateY(-100%)' }
        }}
        //contentProps={{ style: staticModalStyles }}
      >
      <ModalCloseTarget>
        <button className="modalClose"><FontAwesomeIcon icon={faTimesCircle} /></button>
      </ModalCloseTarget>
        {this.renderPopup()}
      </CenterModal>

        <Box
          wrap={true}
          direction="row"
        >

        { this.state.judges.map( (judge, index) => {
          return (
            <Judge key={index} t={this.props.t} delay={index} judge={judge} popup={this.state.popup} setPopup={this.setPopup} />
          )
        })}

        </Box>
      </Box>
    );
  }
}

export default withTranslation("translations")(ComponentJudges);
