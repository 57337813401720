import * as contentful from 'contentful';

const ContentfulClient = contentful.createClient({
  space: process.env.REACT_APP_SPACE_ID,
  accessToken: process.env.REACT_APP_ACCESS_TOKEN,
  environment: process.env.REACT_APP_CONTENTFUL_ENV,
  resolveLinks: true
})

export default ContentfulClient;
