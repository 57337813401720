import React from 'react';
import {
  Accordion,
  AccordionPanel,
  Box,
  Text,
} from 'grommet'
import RenderRichText from '../../utils/RenderRichText';
import ContentWrap from '../ContentWrap';

const AccordionComponent = ({field}) => {
  console.log("Accordion")
  console.log(field)

  const renderText = (panel) => {
    if (panel.fields.hasOwnProperty('text') && panel.fields.text){
      return (
        <Text dangerouslySetInnerHTML={{ __html: RenderRichText(panel.fields.text) }} />
      )
    } else {
      return (
        <Text>No Text</Text>
      )
    }
  }

  return (
    <ContentWrap>
      <Accordion
        className="accordion"
        //height="medium"
        //background={'url(' + content.fields.image.fields.file.url + ')'}
      >
        { field.fields.panels.map( (panel, index) => {
          return (
            <AccordionPanel className="accordionPanel" key={index} label={panel.fields.title}>
              {renderText(panel)}
            </AccordionPanel>
          )
        })}
    </Accordion>
    </ContentWrap>
  )
}

export default AccordionComponent;
