import React, { useState, useEffect } from 'react';
import Settings from '../constants/Settings';
import CategoryComponent from './Category.js';
import { calcRowBasis } from '../utils/Misc';
import { animated, useSpring } from "react-spring";
import { Link } from "react-router-dom";
import {
  Box,
  Button,
  Image,
  Markdown,
  ResponsiveContext,
} from 'grommet';
import { CenterModal, ModalCloseTarget } from 'react-spring-modal';

import { connect } from 'react-redux'
import { addVote } from '../actions/cartActions'

import header from '../assets/images/categories-header.png';
import header_2x from '../assets/images/categories-header@2x.png';
import hof_logo from '../assets/images/HOF-LOGO-transparent-white-small.png';
import hof_logo_2x from '../assets/images/HOF-LOGO-transparent-white-small@2x.png';
import card_bg from '../assets/images/inductee-card-bg.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { parseQueryString } from "../utils/Misc";
import _ from 'lodash';
import Analytics from '../utils/Analytics';
import Countdown from './Countdown.js';
import YoutubeEmbed from './content/YoutubeEmbed';

const CategoriesComponent = ({
  t,
  i18n,
  match,
  categories,
  votes,
  voted,
  addVoteState,
  countdownDate,
  showCountdown,
  countdownText,
}) => {
  const [category, setCategory] = useState(false); // Will hold the selected / open category
  const [showCategory, setShowCategory] = useState(false);
  const [popup, setPopup] = useState(false);
  const [popupOpen, setPopupOpen] = useState(false);
  const size = React.useContext(ResponsiveContext);
  const queryString = parseQueryString();

  useEffect(() => {
    if (queryString.hasOwnProperty('category') && queryString.category){
      //if (category && category.id !== queryString.category){
      if (category.id !== queryString.category){
        // Find category in categories and set
        var foundCategory = _.find(categories, function(o) { return o.fields.id === queryString.category})
        if (foundCategory){
          setShowCategory(true)
          setCategory(foundCategory)
        }
      }
    }
  },[queryString]);

  useEffect(() => {
    if (queryString.hasOwnProperty('category') && queryString.category){
      if (categories.length > 0){

        // Find category in categories and set
        var foundCategory = _.find(categories, function(o) { return o.fields.id === queryString.category})
        if (foundCategory){
          //setCategoryAnimation(foundCategory);
          setShowCategory(true)
          setCategory(foundCategory)
          scrollToProducts()
        }
      } else {
        //console.log("no categories yet");
      }
    }
  },[categories]);

  const renderInducteeImage = (product) => {
    if (
      product.hasOwnProperty('image')
    ){
      return (
        <img
          className="inducteeImage"
          alt={product.name}
          src={product.image.fields.file.url}
        />
      )
    } else {
      return (
        <p>No inductee image</p>
      )
    }
  }

  const renderInducteeName = (product) => {
    if (
      product.hasOwnProperty('name')
      && product.name
    ){
      return (
        <h3>{product.name}</h3>
      )
    } else {
      return null;
    }
  }

  const renderInducteeSpecs = (product) => {
    if (
      product.hasOwnProperty('specs')
      && product.specs
    ){
      return (
        <Box
          className="specs"
          direction="row"
          justify="stretch"
        >
          { product.specs.map( (spec, index) => {
            return (
              <Box className="spec" flex="grow" key={index}>
                <h4 className="specTitle">{spec.fields.title}</h4>
                <div className="specValue">{spec.fields.value}</div>
              </Box>
            )
          })}
        </Box>
      )
    } else {
      return null
    }
  }

  const renderInducteeShortDescription = (product) => {
    if (
      product.hasOwnProperty('shortDescription')
    ){
      return (
        <p className="shortDescription">{product.shortDescription}</p>
      )
    } else {
      return null
    }
  }

  const renderInducteeLongDescription = (product) => {
    if (
      product.hasOwnProperty('longDescription')
      && product.longDescription
    ){
      return (
        <Box className="longDescription">
          <Markdown>{product.longDescription }</Markdown>
          {renderInducteeCTAText(product)}
        </Box>
      )
    } else {
      return null
    }
  }

  const renderInducteeCTAText = (product) => {
    if (
      product.hasOwnProperty('cta01')
      && product.cta01
      && product.hasOwnProperty('ctaLink')
      && product.ctaLink
    ){
      return (
        <p>
          <a style={{color: "#140d40"}} className="ctaLink" href={product.ctaLink} target="_blank">
          {product.cta01}
          </a>
        </p>
      )
    } else {
      return null
    }
  }

  const renderInducteeCTA = (product) => {
    if (
      product.hasOwnProperty('cta01')
      && product.cta01
      && product.hasOwnProperty('ctaLink')
      && product.ctaLink
    ){
      return null // disabled
      return (
        <Box className="footer" pad="medium" justify="center" align="center">
          <Button primary label={product.cta01} href={product.ctaLink} target="_blank" />
        </Box>
      )
    } else {
      return null
    }
  }

  const renderInducteeVideo = (product) => {
    if (
      product.hasOwnProperty('videoId')
      && product.videoId
    ){
      return (
        <Box className="video">
          <YoutubeEmbed embedId={product.videoId} />
        </Box>
      )
    } else {
      return null
    }
  }

  const openPopup = (item) => {
    //console.log(item)
    Analytics.TrackPageView(item.name, "/Nominee/" + _.kebabCase(item.name));
    setPopup(item);
    setPopupOpen(true);
  }

  const closePopup = (item) => {
    setPopupOpen(false);
    setTimeout(() => { setPopup(item)}, 500)
  }

  const renderPopup = () => {
    if (!popup){
      return null
    } else {
      return (
        <Box className="popupInductee">
          <Box flex={{grow: 0,shrink: 1}} className="header">
            {renderInducteeImage(popup)}
            {renderInducteeName(popup)}
          </Box>
          <Box flex={{grow: 1, shrink: 0}} className="body">
            {renderInducteeShortDescription(popup)}
            {renderInducteeSpecs(popup)}
            {renderInducteeLongDescription(popup)}
            {renderInducteeVideo(popup)}
          </Box>
            {renderInducteeCTA(popup)}
        </Box>
      )
    }
  }

  const scrollToNominees = () => {
    var hashElement = document.getElementById('nomineesAnchor')
    //hashElement.scrollIntoView(true);
    hashElement.scrollIntoView({ behavior: 'smooth', block: "start" })
  }

  const scrollToProducts = () => {
    var hashElement = document.getElementById('productsAnchor')
    hashElement.scrollIntoView({ behavior: 'smooth', block: "start"} )
    //hashElement.scrollIntoView({ behavior: 'smooth' })
  }

  const setCategoryAnimation = (category) => {
    //scrollToNominees()
    scrollToProducts()
    // Set get variable
    window.history.replaceState(null, null, "?category=" + category.fields.id + "#nominees");
    // Animate from one category to the next

    if (!showCategory){
      setCategory(category)
      setShowCategory(true)
    } else {
      category.show = false
      setShowCategory(false)
      setTimeout( () => {
        setCategory(category)
        setShowCategory(true)
      }, 300)
    }
  }

  const checkIfProductSelected = (category, product) => {
    if (votes.hasOwnProperty(category.fields.id) && votes[category.fields.id] && product.id === votes[category.fields.id].id){
      return true
    }
    return false;
  }

  // #TODO - use reactspring trails e.g. https://codesandbox.io/s/49jsc?file=/src/index.js
  const fade = useSpring({
    opacity: showCategory ? '1' : '0',
    minHeight: showCategory ? '500px' : '0',
      //color: isToggled ? '#000' : 'red',
      //transform: isToggled
      //  ? 'translate3d(0, 30px, 0)'
      //  : 'translate3d(0, 30px, 0)',
      //fontSize: isToggled ? '2rem' : '3rem',
    });

  // Voting
  const vote = (product, category, category_id) => {
    // add vote to state
    addVoteState(product, category, category_id);
  }

  const renderCountdown = () => {
    if (showCountdown){
      return (
        <>
          <Box className="categoriesContainer categoriesContainerCountdown">
            <Countdown t={t} countdownText={countdownText} categories={categories} countdownDate={countdownDate} />
          </Box>
          <Box className="categoriesCountdownLink" style={{marginBottom: "100px"}}>
            <Link
              to={`/${i18n.language}/class-of-2020`}
              style={{display: "block", textAlign: "center"}}
            >
              <Button primary label={t("View the class of 2020")} fill="horizontal" hoverIndicator={false} focusIndicator={false} />
            </Link>
          </Box>
        </>
      )
    } else {
      return null
    }
  }

  const showCategories = () => {
    if (Settings.SHOW_CATEGORIES){
      // Return the categories
      return (
        <Box className={"categoriesBackgroundContainer" + (showCountdown ? " categoriesBackgroundContainerCountdown" : "")}>
          {renderCountdown()}

          <div id="2021nominees"></div>

          <Box className="categoriesContainer">
            { categories.map( (thisCategory, index) => {
              return (
                <CategoryComponent
                  key={index}
                  t={t}
                  i18n={i18n}
                  callback={setCategoryAnimation}
                  category={thisCategory}
                  selected={category}
                  match={match}
                />
              )
            })}
          </Box>

          <div id="nomineesAnchor"></div>

          {showProducts()}
        </Box>
      )
    } else {
      // Show a countdown timer instead
      return (
        <Box className="categoriesBackgroundContainer categoriesBackgroundContainerCountdown">
          <div id="nomineesAnchor"></div>
          <Box className="categoriesContainer categoriesContainerCountdown">
            <Countdown t={t} categories={categories} countdownDate={countdownDate} />
          </Box>
          <Box className="categoriesCountdownLink">
            <Link
              to={`/${i18n.language}/class-of-2020`}
              style={{display: "block", textAlign: "center"}}
            >
              <Button primary label={t("View the class of 2020")} fill="horizontal" hoverIndicator={false} focusIndicator={false} />
            </Link>
          </Box>
        </Box>
      )
    }
  }

  const showProducts = () => {
    if (!category){
      return <div id="productsAnchor"></div>
    } else {
      return (
        <animated.div style={fade}>
          <div id="productsAnchor"></div>
          <Box
            //direction="row"
            //wrap={true}
            //gap="medium"
            //id="nominees"
            id="nomineesContainer"
            className="nominees"
            //pad="large"
          >
          { category.products.map( (product, index) => {
            const nomineeSelected = checkIfProductSelected(category, product)
            var voteButton = null
            if (!Settings.ALLOW_VOTING || Settings.VOTING_CLOSED){
              voteButton = null;
            } else {
              if (nomineeSelected){
                if (voted){
                  voteButton = null // Hide vote button if voted
                } else {
                  voteButton = <Button
                    hoverIndicator={false}
                    focusIndicator={false}
                    primary
                    label={voted ? t("voted") : t("selected")}
                  />
                }
              } else {
                if (voted){
                  voteButton = null // Hide vote button if voted
                } else {
                  voteButton = <Button
                    hoverIndicator={false}
                    focusIndicator={false}
                    secondary
                    label={t("vote for") + " " + product.name}
                    onClick={ () => vote(product, category.fields, category.fields.id)}
                  />
                }
              }
            }

            return (
              <Box
                className={nomineeSelected ? "nominee nomineeSelected" : "nominee"}
                test={checkIfProductSelected(category, product)}
                key={index}
                //basis={calcRowBasis(size)}
                //margin="1%"
                //justify="between"
                //flex="grow"
              >
                  { voted && nomineeSelected ? <div className="voted">
                    {t('voted')}
                  </div>: null}
                  <img
                    className="nomineeImage"
                    src={product.image.fields.file.url}
                  />

                  <Box className="textWrapper">
                    <h3>{product.name}</h3>
                    <p className="shortDescription">{product.shortDescription}</p>
                  </Box>
                  <Box className="buttonWrapper">
                    <Button hoverIndicator={false} focusIndicator={false} className="detailsButton" primary label={t('details')} onClick={ () => openPopup(product) } />
                    {voteButton}
                  </Box>

              </Box>
            )
          })}
              <Box
                className="nominee"
                basis={calcRowBasis()}
                margin="1%"
                flex="grow"
                pad="medium"
                background={`url(${card_bg})`}
              >
                <Image
                  className="categoryImage"
                  fit="contain"
                  fill={false}
                  src={hof_logo}
                  srcSet={`${hof_logo} 1x, ${hof_logo_2x} 2x, `}
                />
                <Link
                  to={`/${i18n.language}/class-of-2020`}
                  style={{width: "100%", display: "block", textAlign: "center"}}
                >
                  <Button primary label={t("View Class of 2020")} style={{width: "100%"}} />
                </Link>
              </Box>
          </Box>
        </animated.div>
      )
    }
  }

  const showHeaderImage = () => {
    if (Settings.SHOW_CATEGORIES){
      return <img
          src={header}
          srcSet={`${header} 1x, ${header_2x} 2x, `}
          id="categories_header"
          alt="Hall of Fame Categories"
          style={{
            width: "100%",
            maxWidth: "222px"
          }}
        />
    } else {
      return null;
    }
  }

  return (
    <Box
      id="nominees"
      align="center"
    >

        <CenterModal
          isOpen={popupOpen}
          onDismiss={() => closePopup(false)}
          contentTransition={{
            from: { background: 'black', transform: 'translateY(-100%)' },
            enter: { background: 'black', transform: 'translateY(0)' },
            leave: { background: 'black', transform: 'translateY(-100%)' }
          }}
          //contentProps={{ style: staticModalStyles }}
        >
          <ModalCloseTarget>
            <button className="modalClose"><FontAwesomeIcon icon={faTimesCircle} /></button>
          </ModalCloseTarget>
            {renderPopup()}
        </CenterModal>

        {showHeaderImage()}

      {showCategories()}

    </Box>
  )
}

// Map redux dispatches to props (remember to export below with this function)
const mapDispatchToProps = (dispatch) => {
    return {
        addVoteState: (product, category, category_id) => { dispatch(addVote(product, category, category_id)) }
    }
}

// Map redux state to props (remember to export below with this function)
const mapStateToProps = (state) => {
    return {
      votes: state.cart.votes,
      voted: state.cart.voted,
    }
}

//export default CategoriesComponent;
// Using null as first argument as no mapStateToProps (https://stackoverflow.com/questions/35443167/dispatch-is-not-a-function-when-argument-to-maptodispatchtoprops-in-redux)
export default connect(mapStateToProps, mapDispatchToProps)(CategoriesComponent)
