import React, { useState } from "react";
import { render } from "react-dom";
import { connect, Provider } from "react-redux";
import { Link, Redirect, Route, Switch } from "react-router-dom";
//import { createBrowserHistory } from "history";
import { pathToRegexp, compile } from 'path-to-regexp';
import { I18nextProvider, useTranslation, Trans } from "react-i18next";
import { setLocale, clearVotes } from './actions/cartActions'
import './assets/sass/app.scss';
import logo from './assets/images/logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram, faTwitter, faYoutube, faTiktok } from '@fortawesome/free-brands-svg-icons';
import { CookieBanner } from '@palmabit/react-cookie-law';
import Cookies from 'universal-cookie';
import Settings from './constants/Settings';
import {Helmet} from "react-helmet";
import _ from 'lodash';

// Grommet
import {
  Box,
  Button,
  Collapsible,
  Footer,
  FormField,
  Grommet,
  Layer,
  ResponsiveContext,
  Select,
  Text
} from 'grommet';
import { FormClose } from 'grommet-icons';
import Theme from './utils/Theme';

import i18n from "./i18n";

import { ConnectedRouter } from 'connected-react-router'
import configureStore, { history } from './reducers/configureStore'

// Components
import MyNav from './components/Nav';
import VoteCart from './components/VoteCart';
import NotFound from './components/NotFound';
import LanguageSelector from './components/utility/LanguageSelector';
import ScrollToTop from './components/utility/ScrollToTop';

// Pages
import About from "./pages/About";
import Home from "./pages/Home";
import Class2021 from "./pages/Class-of-2021";
import Class2020 from "./pages/Class-of-2020";
import Categories from './pages/Categories';
import Judges from './pages/Judges';
import Terms from './pages/Terms';
import Privacy from './pages/Privacy';
import Press from './pages/Press';
import Committee from './pages/Committee';
import TestPage from './pages/Test';

// store
import { saveState } from './utils/LocalStorage';
const store = configureStore({}) /* provide initial state if any */

// Cookies
const cookies = new Cookies();

// This method will save store state to local storage
store.subscribe(() => {
  saveState({
    cart:store.getState().cart,
  });
});

// i18n
const lang = i18n.language;

const generateLanguage = (locale, location) => {
  const ROUTE = "/:locale/:path*";
  const definePath = compile(ROUTE);
  const routeComponents = pathToRegexp(ROUTE).exec(location.pathname);

  let subPaths = null;
  if (routeComponents && routeComponents[2]) {
    subPaths = routeComponents[2].split("/");
  }

  return definePath({
    locale,
    path: subPaths
  });
};

const changeLanguage = (lng, dispatch) => {
  i18n.changeLanguage(lng);
  setLanguageCookie(lng);
  if (dispatch){
    dispatch(lng);
  }
  setTimeout(() => {window.location.reload()}, 500) // Reload to fetch new content from Contentful
};

const chooseInitialLanguage = () => {
  // Choose initial Language based on cookie of browser language
  //check for language cookie
  var languageCookie = getLanguageCookie()
  if (languageCookie && languageCookie !== 'undefined'){
    i18n.changeLanguage(languageCookie);
    return languageCookie;
  } else {
    // Chgeck for browser preferred languages
    var browserLanguages = navigator.languages;
    if (browserLanguages){
      for (var i = 0; i < browserLanguages.length; i++) {
        var lang = browserLanguages[i];

        // Exact matches
        if (Settings.languages.includes(lang)){
          i18n.changeLanguage(lang);
          return lang
        }

        // UK Matches
        if (Settings.languagesUK.includes(lang)){
          i18n.changeLanguage(lang);
          return "en-GB"
        }

        // EU Matches
        if (Settings.languagesEU.includes(lang)){
          i18n.changeLanguage(lang);
          return "en-GB" // EU uses uk language
        }

        // French Matches
        if (Settings.languagesEU.includes(lang)){
          i18n.changeLanguage(lang);
          return "fr-FR"
        }

        // German MAtches
        if (Settings.languagesDE.includes(lang)){
          i18n.changeLanguage(lang);
          return "de-DE"
        }
      }
      return Settings.languageFallback;
    } else {
      // #TODO - ip based lookup?
      //return "/en-US"
      return Settings.languageFallback;
    }
  }
  // Fallback
  return Settings.languageFallback;
}

const setLanguageCookie = (lng) => {
    cookies.set('language', lng, { path: '/' });
}
const getLanguageCookie = () => {
  return cookies.get('language')
}

let App = ({ match, location, items, total, votes, setLocale, clearVotes }) => {
  const [showNav, setShowNav] = useState(false);
  const [showSidebar, setShowSidebar] = useState(false);
  const { t } = useTranslation();

  if (lang !== match.params.locale) {
    //console.log(`lang: ${lang} / locale: ${match.params.locale}`);
    // #TODO - had to comment out because of infinite loop
    //changeLanguage(match.params.locale);
  }

  const getLanguageMetaTag = () => {
    if (i18n.language === "eu"){
      return  <meta name="content-language" content={i18n.language} />
    } else {
      return  <meta name="content-language" content={i18n.language} />
    }
  }

  const getFacebookLink = () => {
    if (i18n.language === "en-US"){
      return "https://www.facebook.com/mpbcomUS/"
    }
    if (i18n.language === "en-GB"){
      return "https://www.facebook.com/mpbcom"
    }
    if (i18n.language === "de-DE"){
      return "https://www.facebook.com/mpbcomDE"
    }
    if (i18n.language === "fr-FR"){
      return "https://https://www.facebook.com/mpbcomFR"
    }
    if (i18n.language === "eu"){
      return "https://www.facebook.com/mpbcom"
    }
    return "https://www.facebook.com/mpbcomUS/"
  }

  const checkForVotes = () => {
    // Check if we have any votes in the cart
    var has_votes = _.omitBy(votes, _.isEmpty);
    if (_.isEmpty(has_votes)){
      return false;
    } else {
      return true;
    }
  }

  return (
    <Grommet theme={Theme} themeMode="dark">
    {/**
    <Box style={{
      zIndex: 1001,
      position: "fixed",
      bottom: "100px",
      left: "10px",
    }}>
      <Button style={{padding: "10px", fontSize: "10px", lineHeight: "14px"}} label="CLEAR APP STATE" onClick={() => clearVotes()} />
    </Box>
    **/}
    <Helmet>
      {getLanguageMetaTag()}
    </Helmet>
    <ResponsiveContext.Consumer>
   {size => (
     <Box fill>
       <Box
        id="content"
        //flex={{"grow":4, "shrink": 1}}
        //overflow={{"vertical": "scroll"}}
      >
        <MyNav
          id="nav"
          i18n={i18n}
          match={match}
          location={location}
          showSideBar={() => setShowSidebar(!showSidebar)}
          showNav={showNav}
          setShowNav={setShowNav}
        />
        <Box className="contentWrapper" direction='row' flex="grow" overflow={{}}>
          <CookieBanner
            //message="This website used cookies to give you the best experience, please choose the cookies you are happy to accept and submit your preferences"
            message={t("cookie_msg")}
            wholeDomain={false}
            policyLink={`${match.url}/privacy-policy`}
            privacyPolicyLinkText={t("Privacy Policy")}
            necessaryOptionText={t("Necessary")}
            preferencesOptionText={t("Preferences")}
            statisticsOptionText={t("Statistics")}
            marketingOptionText={t("Marketing")}
            acceptButtonText={t("Accept all")}
            declineButtonText={t("Decline")}
            managePreferencesButtonText={t("Manage cookies")}
            onAccept = {() => {}}
            onAcceptPreferences = {() => {}}
            onAcceptStatistics = {() => {}}
            onAcceptMarketing = {() => {}}
          />
          <Box className="switchWrapper" width="100%" align='center' justify='start'>
              <ScrollToTop />
              <Switch>
                <Route exact path={`${match.url}/`} component={Home} />
                <Route path={`${match.url}/class-of-2021`} component={Class2021} />
                <Route path={`${match.url}/class-of-2020`} component={Class2020} />
                <Route path={`${match.url}/about-mpb`} component={About} />
                <Route path={`${match.url}/press-and-media`} component={Press} />
                <Route path={`${match.url}/committee`} component={Committee} />
                <Route path={`${match.url}/nominees`} component={Categories} />
                <Route path={`${match.url}/privacy-policy`} component={Privacy} />
                <Route path={`${match.url}/terms-and-conditions`} component={Terms} />
                <Route path={`${match.url}/test-page`} component={TestPage} />
                <Route path={`${match.url}/not-found`} component={NotFound} />
                <Route component={NotFound} match={match} />
              </Switch>
          </Box>
          {(!showSidebar || size !== 'small') ? (
            <Collapsible direction="horizontal" open={showSidebar}>
            <Box
              width='medium'
              background='light-2'
              elevation='small'
              align='center'
              justify='center'
            >
              sidebar
            </Box>
          </Collapsible>
          ): (
           <Layer>
            <Box
               background='light-2'
               tag='header'
               justify='end'
               align='center'
               direction='row'
             >
               <Button
                 icon={<FormClose />}
                 onClick={() => setShowSidebar(false)}
               />
             </Box>
             <Box
               fill
               background='light-2'
               align='center'
               justify='center'
             >
               sidebar
             </Box>
           </Layer>
           )}
        </Box>

            <Footer className={checkForVotes() ? "haveVotes" : ""} align="end">
                <Box className="footerLeft" justify="end">
                  <img
                    className="footerLogo"
                    src={logo}
                    alt="MPB"
                    style={{
                       width: "100px"
                    }}
                  />

                  <Text className="footerCopyright">
                    © 2022 MPB
                  </Text>

                  <div style={{display: "none"}}>
                    <p>Current locale: {match.params.locale}</p>
                    <p>Current path: {match.path}</p>
                  </div>
                </Box>

                <Box className="footerRight" align="end">
                  <Box direction="row" className="socialLinks">
                    <a target="_blank" href="https://www.instagram.com/mpbcom/"><FontAwesomeIcon icon={faInstagram} /></a>
                    <a target="_blank" href={getFacebookLink()}><FontAwesomeIcon icon={faFacebook} /></a>
                    <a target="_blank" href="https://twitter.com/mpbcom"><FontAwesomeIcon icon={faTwitter} /></a>
                    <a target="_blank" href="https://www.youtube.com/c/mpbcom"><FontAwesomeIcon icon={faYoutube} /></a>
                    <a target="_blank" href="https://www.tiktok.com/@mpbcom/"><FontAwesomeIcon icon={faTiktok} /></a>
                  </Box>

                  <Box direction="row"  className="pageLinks">
                    <LanguageSelector
                      i18n={i18n}
                      match={match}
                      location={location}
                    />
                    <Link to={`${match.url}/about-mpb`}>
                      <Trans>About MPB</Trans>
                    </Link>
                    <Link to={`${match.url}/privacy-policy`}>
                      <Trans>Privacy Policy</Trans>
                    </Link>
                    <Link to={`${match.url}/terms-and-conditions`}>
                      <Trans>Terms & Conditions</Trans>
                    </Link>
                  </Box>
                </Box>
          </Footer>
        </Box>
        <VoteCart t={t} i18n={i18n} match={match} />
      </Box>
       )}
       </ResponsiveContext.Consumer>
      </Grommet>
  );
};

// Map redux dispatches to props (remember to export below with this function)
const mapDispatchToProps = (dispatch) => {
  return {
    setLocale: (locale) => { dispatch(setLocale(locale)) },
    clearVotes: () => { dispatch(clearVotes()) },
  }
}

const mapStateToProps = state => ({
  pathname: state.router.location.pathname,
  search: state.router.location.search,
  hash: state.router.location.hash,
  locale: state.cart.locale,
  items: state.cart.items,
  total: state.cart.total,
  votes: state.cart.votes,
})

App = connect(mapStateToProps, mapDispatchToProps)(App)

const AppTranslated = ({ store, history }) => {
  return (
  <I18nextProvider i18n={i18n}>
    <Provider store={store}>
      <ConnectedRouter history={history}> { /* place ConnectedRouter under Provider */ }
        <Switch>
          <Route path="/:locale" component={App} />
          <Redirect to={'/' + chooseInitialLanguage()} />
        </Switch>
      </ConnectedRouter>
    </Provider>
  </I18nextProvider>
  )
}

render(
  <AppTranslated store={store} history={history} />,
  document.getElementById("root")
  //document.body
);
