//import React from 'react';
import { Link } from "react-router-dom";
import {
  Box,
  Nav,
} from 'grommet';

import { Trans } from "react-i18next";
import { animated, useSpring, useTrail } from "react-spring";
import useWindowSize from '../utils/WindowSize';
import { scrollToID } from '../utils/Misc';
import Settings from '../constants/Settings';
import LanguageSelector from './utility/LanguageSelector';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram, faTwitter, faYoutube, faTiktok } from '@fortawesome/free-brands-svg-icons';

import logo from '../assets/images/logo.png';
import hof_logo from '../assets/images/HOF-LOGO-transparent-white.png';
import hof_logo_2x from '../assets/images/HOF-LOGO-transparent-white@2x.png';


const MyNav = ({ i18n, match, location, showSideBar, showNav, setShowNav }) => {

  // window size
  const size = useWindowSize();

  // React springs
  const navSpring = useSpring({
      //color: showNav ? 'green' : 'red',
      //height: showNav ? (size.height - 60) + 'px' : '41px',
      height: showNav ? (size.height - 0) + 'px' : '41px',
      //flexBasis: showNav ? size.height + 'px' : '41px',
      //transform: isToggled
      //  ? 'translate3d(0, 30px, 0)'
      //  : 'translate3d(0, 30px, 0)',
      //fontSize: showNav ? '2rem' : '3rem',
  });

  const navContentSpring = useSpring({
      delay: showNav ? 200 : 0,
      //reverse: showNav,
      display: showNav ? "flex" : "none",
      height: showNav ? '100%' : '0%',
      opacity: showNav ? '1' : '0',
  });

  const hamburgerSpringTop = useSpring({
      transform: showNav ? 'rotate(45deg)' : 'rotate(0deg)',
  });

  const hamburgerSpringMiddle = useSpring({
      opacity: showNav ? '0' : '1',
  });

  const hamburgerSpringBottom = useSpring({
      transform: showNav ? 'rotate(-45deg)' : 'rotate(0deg)',
  });

  // #TODO - add useTrail react spring for menu links

  const toggleNav = () => {
    setShowNav(!showNav)
  }

  const hideNav = () => {
    setShowNav(false)
  }

  const getFacebookLink = () => {
    if (i18n.language === "en-US"){
      return "https://www.facebook.com/mpbcomUS/"
    }
    if (i18n.language === "en-GB"){
      return "https://www.facebook.com/mpbcom"
    }
    if (i18n.language === "de-DE"){
      return "https://www.facebook.com/mpbcomDE"
    }
    if (i18n.language === "fr-FR"){
      return "https://https://www.facebook.com/mpbcomFR"
    }
    if (i18n.language === "eu"){
      return "https://www.facebook.com/mpbcom"
    }
    return "https://www.facebook.com/mpbcomUS/"
  }

return (
  <animated.div
    config={{ duration: 500 }}
    id="header"
    className={showNav ? 'headerOpen' : ''}
    background="blueDark"
    align="start"
    style={{
      flexGrow: 0,
      flexShrink: 0,
      display: "flex",
      flexDirection: "column",
      backgroundColor: "#050507",
      ...navSpring
    }}
  >

  <Box
    id="mainNavTop"
    direction="row"
    align="center"
    justify="between"
  >
      <Box id="logoContainer" direction="row" align="center" gap="small">
        <Link to={`${match.url}`} onClick={hideNav}>
          <img
            id="mpb_logo"
            src={logo}
            alt="MPB"
          />
        </Link>
        {/**<Heading level='3' margin='none'>MPB</Heading>**/}
      </Box>

      <Nav direction="row">

        {/**<Button icon={<Notification />} onClick={showSideBar} />**/}
        {/**
        <Link color="navLink" to={`${match.url}`}>
          <Button color="navLink"><Trans>Home</Trans></Button>
        </Link>
        <Link to={`${match.url}/class-of-2020`}>
          <Button color="navLink"><Trans>Class of 2020</Trans></Button>
        </Link>

        <Link to={`${match.url}/Categories`}>
          <Button color="navLink">Categories</Button>
        </Link>
         **/}
        {/**
        <Link to={`${match.url}/Vote`}>
          <Button color="navLink">Vote</Button>
        </Link>

        <Link to={`${match.url}/Committee`}>
          <Button color="navLink">Selection Committee</Button>
        </Link>
        <Link to={`${match.url}/about`}>
          <Button color="navLink">About</Button>
        </Link>
                 **/}
        {/**
        <Link to={`${match.url}/topics`}>
          <Button color="navLink">Topics</Button>
        </Link>
         **/}

         <svg onClick={() => { setShowNav(!showNav) }} id="hamburger" viewBox="0 0 100 80" width="40" height="40">
           <animated.rect style={{transformOrigin: "0% 25%", ...hamburgerSpringTop}} className="hamburger-line" width="100" height="10"></animated.rect>
           <animated.rect style={hamburgerSpringMiddle} className="hamburger-line" y="30" width="100" height="10"></animated.rect>
           <animated.rect style={{transformOrigin: "25% 75%", ...hamburgerSpringBottom}} className="hamburger-line" y="60" width="100" height="10"></animated.rect>
         </svg>

      </Nav>
    </Box>
    <animated.div
      id="mainNavBottom"
      style={{
        display: "flex",
        alignContent: "stretch",
        flexDirection: "column",
        ...navContentSpring
      }}
    >
      <div
       id="navContent"
     >
       <div className="navContentCol">
         <img
           src={hof_logo}
           srcSet={`${hof_logo} 1x, ${hof_logo_2x} 2x, `}
           id="hof_logo"
           alt="The Photo and Video Kit Hall of Fame - Brought to you by MPB"
           style={{
           }}
         />
        </div>
        <div className="navContentCol" id="navLinks">
          <div id="navLinksContainer">
            <Link to={`${match.url}`} onClick={toggleNav}>
              <Trans>Home</Trans>
            </Link>
            {
              Settings.SHOW_CATEGORIES ?
                <Link
                  to={`${match.url}#inductees`}
                  onClick={() => {
                    toggleNav();
                    scrollToID("inductees")
                  }}
                >
                  <Trans>Class of 2022</Trans>
                </Link>
              :
              null
          }
            <Link to={`${match.url}/class-of-2021`} onClick={toggleNav}>
              <Trans>Class of 2021</Trans>
            </Link>
            <Link to={`${match.url}/class-of-2020`} onClick={toggleNav}>
              <Trans>Class of 2020</Trans>
            </Link>
            <Link to={`${match.url}/Committee`} onClick={toggleNav}>
              <Trans>Selection Committee</Trans>
            </Link>
            <Link to={`${match.url}/press-and-media`} onClick={toggleNav}>
              <Trans>Press &amp; Media</Trans>
            </Link>
            <Link to={`${match.url}/about-mpb`} onClick={toggleNav}>
              <Trans>About MPB</Trans>
            </Link>
          </div>
        </div>
      </div>
      <div
       id="navContentFooter"
     >
      <div id="navContentFooterCopyright">
        &copy; MPB 2021
      </div>
      <div id="navContentFooterLinks">
        <LanguageSelector
          i18n={i18n}
          match={match}
          location={location}
        />
        {/**
        <Link to={`${match.url}/about-mpb`} onClick={toggleNav}>
          <Trans>About MPB</Trans>
        </Link>
        **/}
        <Link to={`${match.url}/privacy-policy`} onClick={toggleNav}>
          <Trans>Privacy Policy</Trans>
        </Link>
        <Link to={`${match.url}/terms-and-conditions`} onClick={toggleNav}>
          <Trans>Terms & Conditions</Trans>
        </Link>
      </div>
      <div id="navContentFooterSocial">
        <a target="_blank" href="https://www.instagram.com/mpbcom/"><FontAwesomeIcon icon={faInstagram} /></a>
        <a target="_blank" href={getFacebookLink()}><FontAwesomeIcon icon={faFacebook} /></a>
        <a target="_blank" href="https://twitter.com/mpbcom"><FontAwesomeIcon icon={faTwitter} /></a>
        <a target="_blank" href="https://www.youtube.com/c/mpbcom"><FontAwesomeIcon icon={faYoutube} /></a>
        <a target="_blank" href="https://www.tiktok.com/@mpbcom/"><FontAwesomeIcon icon={faTiktok} /></a>
      </div>
     </div>
    </animated.div>
  </animated.div>
)
}

export default MyNav;
